import { __decorate } from "tslib";
import { Component, Vue, Emit, Ref, } from 'vue-property-decorator';
import { LabelService } from '@/sevices';
import { TagType, SourceType, OperateType, ShareType, } from '../../../../typings/organization/label-manage';
import TreeUtils from '@/common/TreeUtils';
import { Empty } from '@h3/antd-vue';
import * as Utils from '@/common/utils';
let LabelTree = class LabelTree extends Vue {
    constructor() {
        super(...arguments);
        // 标签树
        this.labelTree = [];
        // 展开的节点key值
        this.expandedKeys = [];
        // 当前节点
        this.curNodeState = null;
        // 选中的节点状态key值
        this.selectedKeys = [];
        this.SourceType = SourceType;
        this.treeIdPosMap = {};
        this.searchKeyword = '';
        this.curNodeStateId = '';
        this.editDepartmentType = '';
        this.simpleImage = Empty.PRESENTED_IMAGE_SIMPLE;
        this.showMenu = false;
        this.loading = false;
        this.expandNodeId = '';
        this.TagType = TagType;
    }
    // 已加载的节点key值
    get loadedKeys() {
        return this.expandedKeys;
    }
    get canAction() {
        return this.curNodeState && !this.curNodeState.isRoot;
    }
    get routeName() {
        return this.$route.name;
    }
    nodeWrapperClass(record) {
        let result = `node-${record.pos}`;
        if (record.labelType === TagType.Org) {
            result += ' org';
        }
        return result;
    }
    created() {
        this.loading = true;
        this.init().finally(() => {
            this.loading = false;
        });
    }
    /**
     * 初始化树组件
     */
    async init() {
        if (this.routeName === 'labels') {
            await this.getLabelNodes({ shareType: ShareType.Sys });
        }
        else {
            await this.getLabelNodes({ shareType: ShareType.Org });
        }
        let selectNode = {};
        if (this.labelTree[0]) {
            const firstLevelNode = this.labelTree[0];
            selectNode = firstLevelNode;
            if (firstLevelNode.children.length) {
                this.expandedKeys.push(firstLevelNode.id);
                const secondLevelNode = firstLevelNode.children[0];
                selectNode = secondLevelNode;
                if (secondLevelNode.children.length) {
                    this.expandedKeys.push(secondLevelNode.id);
                    const thirdLevelNode = secondLevelNode.children[0];
                    selectNode = thirdLevelNode;
                }
            }
        }
        this.mockSelectNode(selectNode, selectNode.pos);
    }
    /**
     * 展开标签树节点
     */
    async expandTree(expandedKeys) {
        this.expandedKeys = expandedKeys;
    }
    /**
     * 点击标签树节点
     */
    async selectNode(selectedKeys, e) {
        // 如果是当前部门，再单击显示下拉信息，则不让它执行单击程序
        if (!e.node.dataRef ||
            (e.node.dataRef.id === this.curNodeStateId && e.node.dataRef.isClickDropDownBtn))
            return false;
        // 当前节点信息
        this.curNodeState = e.node.dataRef;
        this.curNodeStateId = e.node.dataRef.id;
        const isHasTag = this.curNodeState.children?.some((child) => {
            if (child.labelType === TagType.Label)
                return true;
            if (child.children) {
                return child.children.some((item) => {
                    return item.labelType === TagType.Label;
                });
            }
        });
        if (this.curNodeState.labelType !== TagType.Label) {
            this.curNodeState.isHasTag = isHasTag;
        }
        else {
            this.curNodeState.isHasTag = true;
        }
        if (this.curNodeState.labelType === TagType.Label ||
            (this.curNodeState.labelType !== TagType.Label && !isHasTag)) {
            this.selectedKeys = [e.node.dataRef.id];
        }
        else {
            if (!e.node.dataRef.isClickDropDownBtn) {
                this.selectedKeys = [...this.selectedKeys];
                if (this.expandedKeys.includes(this.curNodeStateId)) {
                    this.deleteExpandKey(this.curNodeStateId);
                }
                else {
                    this.pushExpandKey(this.curNodeStateId);
                }
            }
        }
        return this.curNodeState;
    }
    // 加载标签树源数据
    async getLabelNodes(params) {
        const res = await LabelService.getLabelTree(params);
        const data = res.data;
        if (!res.success) {
            this.$message.error(res.errMessage);
            return;
        }
        this.renderLabelTree(data);
    }
    // 根据节点位置判断是更新原有标签树还是初次生成标签树
    renderLabelTree(labelTreeData) {
        this.labelTree = this.generateLabelTree(labelTreeData, null, [0], true);
    }
    /**
     * 生成标签树
     */
    generateLabelTree(labelTreeData, parentId, pos, isRoot) {
        const tree = [];
        if (!Array.isArray(labelTreeData))
            return tree;
        labelTreeData.forEach((item, index) => {
            let children = [];
            const nodePos = JSON.parse(JSON.stringify(pos));
            nodePos.push(index);
            // 默认第一层树有children则渲染children
            if (item.children && item.children.length) {
                children = this.generateLabelTree(item.children, item.id, nodePos);
            }
            item.parentId = item.parentId ? item.parentId : parentId;
            const node = this.generateLabelNode(item, children, nodePos, isRoot);
            tree.push(node);
        });
        return tree;
    }
    /**
     * 生成标签树的节点
     */
    generateLabelNode(labelData, children = [], pos = [], isRoot = false, isSearch = false) {
        if (!isSearch) {
            this.treeIdPosMap[labelData.id] = pos.join('-');
        }
        const namePath = [];
        if (labelData.organizationName) {
            namePath.push(labelData.organizationName);
        }
        if (labelData.groupName) {
            namePath.push(labelData.groupName);
        }
        namePath.push(labelData.name);
        const node = {
            key: labelData.id,
            title: labelData.name,
            children,
            isLeaf: labelData.leaf,
            scopedSlots: {
                title: 'title',
            },
            showDropDown: false,
            isEdit: false,
            isClickDropDownBtn: false,
            isHasTag: false,
            isRoot,
            pos: pos.join('-'),
            // 标签、部门数据
            id: labelData.id,
            name: labelData.name,
            namePath: namePath.join('/'),
            parentId: labelData.parentId,
            hasChild: !labelData.leaf,
            labelType: labelData.labelType,
            relatedSyncType: labelData.sourceType === SourceType.SelfMaintain ? 'PUSH' : 'PULL',
            sourceType: labelData.sourceType,
        };
        return node;
    }
    /**
     * 展开从搜索选中的节点
     */
    async selectTreeNodeBySearch(item) {
        const nodeState = this.getNodeStateById(item.id);
        if (nodeState) {
            const keys = this.getKeyByPos(nodeState.pos);
            this.expandedKeys = [...new Set([...this.expandedKeys, ...keys])];
            this.selectedKeys = [item.id];
            await this.$nextTick();
            this.setTreeScrollTop(nodeState, this.labelTreeDom);
        }
    }
    /**
     * 自动滚动到可见范围
     */
    setTreeScrollTop(node, treeDom) {
        const scrollTop = treeDom.scrollTop;
        const itemHtml = TreeUtils.getItemHtmlByPos(node.pos, treeDom);
        const li = TreeUtils.getParentItemHtml(itemHtml, 'li');
        const itemOffsetTop = li.offsetTop;
        const treeContentHeight = treeDom.offsetHeight;
        // 不可见范围
        if (itemOffsetTop < scrollTop ||
            itemOffsetTop - treeContentHeight > scrollTop) {
            treeDom.scrollTop = itemOffsetTop - treeContentHeight * 0.5 - li.offsetHeight * 0.5;
        }
    }
    /**
     * 获取节点位置获取层层节点id
     */
    getKeyByPos(pos) {
        const nodePos = pos.split('-');
        // 去掉头部不需要的
        nodePos.shift();
        // 去掉节点自己
        nodePos.pop();
        let treeNode;
        let labelTree = this.labelTree;
        const result = [];
        for (const item of nodePos) {
            treeNode = labelTree[item];
            result.push(treeNode.id);
            const children = treeNode.children || [];
            if (children && children.length) {
                labelTree = children;
            }
        }
        return result;
    }
    /**
     * 收起节点
     * @param node
     */
    async deleteExpandKey(id) {
        const index = this.expandedKeys.findIndex(key => key === id);
        if (index >= 0) {
            this.expandedKeys.splice(index, 1);
        }
    }
    /**
     * 展开节点
     * @param node
     */
    pushExpandKey(id) {
        const index = this.expandedKeys.findIndex(key => key === id);
        if (index < 0) {
            this.expandedKeys.push(id);
        }
    }
    /**
     * 通过ID获取节点
     * @param id
     * @returns
     */
    getNodeStateById(id) {
        const nodePos = this.treeIdPosMap[id];
        if (!nodePos) {
            this.$message.error('标签树没有当前选中的标签');
            return;
        }
        const nodeState = TreeUtils.getTreeNodeStateByPos(this.labelTree, nodePos);
        return nodeState;
    }
    /*
     * 重载树
     */
    async reloadTree() {
        this.init();
    }
    /**
     * 添加部门时重载树
     */
    async reloadTreeByAddLabel(res) {
        const { data } = res;
        this.addLabel(data);
    }
    /**
     * 编辑部门时重载树
     */
    async reloadTreeByEditLabel(res) {
        const { data } = res;
        this.editLabel(data);
    }
    /**
     * 删除时重载树
     */
    async reloadTreeByDelete() {
        let node = null;
        let labelTree = [];
        let parentNode = null;
        if (this.curNodeState.isRoot) {
            // 操作根节点
            labelTree = this.labelTree;
        }
        else {
            // 操作非根节点
            parentNode = this.getNodeStateById(this.curNodeState.parentId);
            if (!parentNode)
                return;
            labelTree = parentNode.children || [];
        }
        ;
        // 删除当前需要删除的节点
        const deleteIndex = labelTree.findIndex(item => item.id === this.curNodeState.id);
        if (deleteIndex >= 0) {
            labelTree.splice(deleteIndex, 1);
        }
        if (labelTree.length) {
            node = labelTree[deleteIndex];
            if (!node) {
                node = labelTree[deleteIndex - 1];
            }
            if (!node) {
                node = labelTree[deleteIndex + 1];
            }
            // 如果不是标签节点，并且有子节点，则需要展开组织或分组节点，并把选中标签
            if (node.labelType === TagType.Group && node.children.length) {
                this.pushExpandKey(node.id);
                node = node.children[0];
            }
        }
        else {
            // 非根节点如果没有子节点
            if (!this.curNodeState.isRoot) {
                parentNode.isLeaf = true;
                node = parentNode;
                this.deleteExpandKey(node.id);
            }
        }
        // 模拟单击选中部门
        this.mockSelectNode(node, node.pos);
    }
    /**
     * 编辑未改变父级部门的部门
     */
    async editLabel(data) {
        const curNode = this.getNodeStateById(data.id);
        curNode.name = data.name;
        curNode.title = data.name;
        curNode.isEdit = true;
        // 模拟单击选中部门
        this.mockSelectNode(curNode, curNode.pos);
        await Utils.sleep(1000);
        curNode.isEdit = false;
    }
    // 追加新增的标签/分组到标签树
    async addLabel(data) {
        let parentNode = null;
        if (data.labelType === TagType.Group) {
            if (data.orgId) {
                parentNode = this.getNodeStateById(data.orgId);
                data.parentId = data.orgId;
            }
        }
        else if (data.labelType === TagType.Label) {
            if (data.parentId) {
                parentNode = this.getNodeStateById(data.parentId);
            }
            else if (data.orgId) {
                parentNode = this.getNodeStateById(data.orgId);
                data.parentId = data.orgId;
            }
        }
        let node = {};
        let nodePos = '';
        if (parentNode) {
            if (parentNode.isLeaf) {
                parentNode.isLeaf = false;
            }
            this.pushExpandKey(parentNode.id);
            nodePos = `${parentNode.pos}-${parentNode.children.length}`;
            node = this.generateLabelNode(data, [], nodePos.split('-').map(item => Number(item)));
            parentNode.children = [...parentNode.children, node];
        }
        else {
            nodePos = `0-${this.labelTree.length}`;
            node = this.generateLabelNode(data, [], nodePos.split('-').map(item => Number(item)), true);
            this.labelTree.push(node);
        }
        this.mockSelectNode(node, nodePos);
    }
    /**
     * 模拟单击选中部门
     */
    mockSelectNode(nodeState, nodePos) {
        // 模拟单击选中父级部门
        const selectEvent = {
            node: {
                dataRef: nodeState,
                pos: nodePos,
            },
        };
        const expandedKeys = [nodeState.id];
        this.selectNode(expandedKeys, selectEvent);
    }
    async visibleChange(record) {
        const dataRef = record.dataRef;
        dataRef.isClickDropDownBtn = true;
        dataRef.showDropDown = !dataRef.showDropDown;
        await Utils.sleep(1000);
        dataRef.isClickDropDownBtn = false;
    }
    /**
     * 添加标签
     */
    addChildLabel(record) {
        const dataRef = record.dataRef;
        dataRef.showDropDown = !dataRef.showDropDown;
        this.$parent.updateLabel(OperateType.Add, dataRef);
    }
    /**
     * 编辑这个标签
     */
    editIt(record) {
        const dataRef = record.dataRef;
        dataRef.showDropDown = !dataRef.showDropDown;
        this.$parent.updateLabel(OperateType.Edit, dataRef);
    }
    /**
     * 编辑这个分组
     */
    editGroup(record) {
        const dataRef = record.dataRef;
        dataRef.showDropDown = !dataRef.showDropDown;
        this.$parent.updateGroup(OperateType.Edit, dataRef);
    }
    /**
     * 添加分组
     */
    addChildGroup(record) {
        const dataRef = record.dataRef;
        dataRef.showDropDown = !dataRef.showDropDown;
        this.$parent.updateGroup(OperateType.Add, dataRef);
    }
    ;
    /**
     * 删除标签
     */
    deleteLabel(record) {
        const dataRef = record.dataRef;
        dataRef.showDropDown = !dataRef.showDropDown;
        this.$confirm({
            title: '确定删除该标签吗？',
            okText: '确定',
            cancelText: '取消',
            content: '删除后，当前标签下的所有信息将会被清除。',
            onOk: () => {
                return new Promise((resolve, reject) => {
                    this.handleDelete(dataRef).then(() => {
                        resolve(null);
                    }).catch((e) => {
                        reject(e);
                    });
                });
            },
        });
    }
    /**
     * 删除分组
     */
    deleteGroup(record) {
        const dataRef = record.dataRef;
        dataRef.showDropDown = !dataRef.showDropDown;
        this.$confirm({
            title: '确定删除该分组吗？',
            okText: '确定',
            cancelText: '取消',
            content: '删除后，当前分组下的所有标签信息将会被清除。',
            onOk: () => {
                return new Promise((resolve, reject) => {
                    this.handleDelete(dataRef).then(() => {
                        resolve(null);
                    }).catch((e) => {
                        reject(e);
                    });
                });
            },
        });
    }
    handleDelete(item) {
        const id = item.id;
        return LabelService.delLabelOrGroup(id).then((res) => {
            if (res.success) {
                this.$message.success('删除成功！');
                this.reloadTreeByDelete();
            }
            else {
                this.$message.error(res.errMessage || '无法删除');
            }
        });
    }
    mouseEnter(e, eData) {
        const { dataRef } = eData;
        this.$set(dataRef, 'isMouseOver', true);
    }
    mouseLeave(e, eData) {
        const { dataRef } = eData;
        dataRef.isMouseOver = false;
    }
};
__decorate([
    Ref()
], LabelTree.prototype, "labelTreeDom", void 0);
__decorate([
    Emit('selectNodeEvent')
], LabelTree.prototype, "selectNode", null);
LabelTree = __decorate([
    Component({
        name: 'LabelTree',
    })
], LabelTree);
export default LabelTree;
