import { __decorate } from "tslib";
import { Component, Vue, Watch, Ref, } from 'vue-property-decorator';
import LabelTree from './label-tree.vue';
import LabelSearch from './label-search.vue';
import * as Utils from '@/common/utils';
import { OperateType } from '../../../../typings/organization/label-manage';
let LabelLeft = class LabelLeft extends Vue {
    constructor() {
        super(...arguments);
        this.keyword = '';
        this.operateType = OperateType.Add;
        this.OperateType = OperateType;
        this.isEnterSearch = false;
        this.showLabelModal = false;
        this.showGroupModal = false;
        this.showSearch = false;
        this.labelDetail = {};
        this.groupDetail = null;
        this.debounce = function () { };
    }
    get routeName() {
        return this.$route.name;
    }
    get updateGroupComponent() {
        let result = null;
        const self = this;
        if (this.routeName === 'labels') {
            self.$options.components.updateGroup = () => import('./labels-update-group.vue');
        }
        else {
            self.$options.components.updateGroup = () => import('./organization-update-group.vue');
        }
        result = self.$options.components.updateGroup;
        return result;
    }
    get updateLabelComponent() {
        let result = null;
        const self = this;
        if (this.routeName === 'labels') {
            self.$options.components.updateLabel = () => import('./labels-update-label.vue');
        }
        else {
            self.$options.components.updateLabel = () => import('./organization-update-label.vue');
        }
        result = self.$options.components.updateLabel;
        return result;
    }
    onKeyworkChange() {
        this.keyword = this.keyword.replace(/^\s*|\s*$/g, '');
        if (this.keyword) {
            this.isEnterSearch = false;
            this.debounce();
        }
        else {
            this.showSearch = false;
        }
    }
    created() {
        this.debounce = Utils.debounce(async () => {
            if (this.keyword) {
                this.showSearch = true;
            }
            else {
                this.showSearch = false;
                return;
            }
            if (this.isEnterSearch)
                return;
            await this.$nextTick();
            this.$refs.LabelSearch.search();
        }, 1000);
    }
    async search() {
        this.isEnterSearch = true;
        if (this.keyword) {
            this.showSearch = true;
            await this.$nextTick();
            this.$refs.LabelSearch.search();
        }
        else {
            this.$message.info('请输入搜索关键字');
        }
    }
    updateLabel(operateType = OperateType.Add, labelDetail) {
        this.operateType = operateType;
        this.labelDetail = labelDetail;
        this.showLabelModal = true;
    }
    reloadTree(data) {
        const { type } = data;
        if (type === 'add') {
            this.LabelTree.reloadTreeByAddLabel(data);
        }
        else if (type === 'update') {
            this.LabelTree.reloadTreeByEditLabel(data);
        }
    }
    updateGroup(operateType = OperateType.Add, groupDetail) {
        this.operateType = operateType;
        this.groupDetail = groupDetail;
        this.showGroupModal = true;
    }
};
__decorate([
    Ref()
], LabelLeft.prototype, "searchComp", void 0);
__decorate([
    Ref()
], LabelLeft.prototype, "LabelTree", void 0);
__decorate([
    Watch('keyword')
], LabelLeft.prototype, "onKeyworkChange", null);
LabelLeft = __decorate([
    Component({
        name: 'LabelLeft',
        components: {
            LabelTree,
            LabelSearch,
        },
    })
], LabelLeft);
export default LabelLeft;
