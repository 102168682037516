import { __decorate } from "tslib";
import { Component, Vue, Prop, Emit, } from 'vue-property-decorator';
import { LabelService } from '@/sevices';
import { TagType, ShareType, } from '../../../../typings/organization/label-manage';
import { Empty } from '@h3/antd-vue';
import axios from 'axios';
const CancelToken = axios.CancelToken;
let cancel = null;
let LabelSearch = class LabelSearch extends Vue {
    constructor() {
        super(...arguments);
        this.selectedKeys = [];
        this.TagType = TagType;
        this.searchList = [];
        this.loading = false;
        this.simpleImage = Empty.PRESENTED_IMAGE_SIMPLE;
    }
    get routeName() {
        return this.$route.name;
    }
    selectNode(item) {
        this.selectSearch(item);
        this.selectTreeNode(item);
        return item;
    }
    selectSearch(item) {
        this.selectedKeys = [item.id];
    }
    selectTreeNode(item) {
        this.$parent.LabelTree.selectTreeNodeBySearch(item);
    }
    /**
     * @description: 搜索
     * @param {type} 组织树关键词
     */
    async search() {
        this.selectedKeys = [];
        if (!this.keyword)
            return Promise.resolve();
        return new Promise((resolve) => {
            if (typeof cancel === 'function') {
                cancel();
            }
            this.loading = true;
            const param = {
                name: this.keyword,
                shareType: this.routeName === 'labels' ? ShareType.Sys : ShareType.Org,
            };
            LabelService.searchLabel(param, {
                cancelToken: new CancelToken(function executor(c) {
                    cancel = c;
                }),
            }).then((res) => {
                if (!res.success) {
                    this.$message.error(res.errMessage);
                    return;
                }
                this.searchList = this.generateLabelNode(res.data);
                resolve(null);
                cancel = null;
            }).finally(() => {
                this.loading = false;
            });
        });
    }
    generateLabelNode(data) {
        return data.map((item) => {
            return this.LabelTree.generateLabelNode(item, [], [], false, true);
        });
    }
};
__decorate([
    Prop()
], LabelSearch.prototype, "keyword", void 0);
__decorate([
    Prop()
], LabelSearch.prototype, "LabelTree", void 0);
__decorate([
    Emit('selectNodeEvent')
], LabelSearch.prototype, "selectNode", null);
LabelSearch = __decorate([
    Component({
        name: 'LabelSearch',
    })
], LabelSearch);
export default LabelSearch;
